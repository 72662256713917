<template>
	<div class="cbox">
		<div class="headbar">
			<div style="float: right;">

				<span>组织架构：</span>
				<el-cascader ref="firstCascader" :options="ckdTreeData" :props="defaultProps" v-model="deptId"
					@change="getTeachers" :show-all-levels="false" placeholder="请选择" filterable clearable
					style="width: 150px;margin-right:10px" size="small">
				</el-cascader>


				<span>搜索关键字：</span>
				<el-input v-model="searchForm.keyword" size="small" placeholder="请输入" clearable
					style="max-width: 200px;margin-right: 10px" />
				<el-button size="small" icon="el-icon-search" @click="getTeachers()" type="primary"
					style="margin-left: 20px;">查询</el-button>

				<div style="float:right">
					<el-button size="small" icon="el-icon-plus" @click="addTeacher" style="margin-left: 20px;">添加用户
					</el-button>
					<!-- <el-button size="small" icon="el-icon-upload2" @click="importTeacherShow=true"
						style="margin-left: 20px;">导入教师</el-button> -->

				</div>



			</div>
			<div style="padding-top: 14px;">
				<el-breadcrumb>
					<el-breadcrumb-item>基础设置</el-breadcrumb-item>
					<el-breadcrumb-item>教师列表</el-breadcrumb-item>
				</el-breadcrumb>
			</div>

		</div>
		<div style="height: 85%;">
			<el-row type="flex" style=" font-size: 14px;">

				<el-col :span="24" class="tabContainer" style="height: 100%;">

					<el-card class="box-card">


						<div class="treeContainer" style="height: 80vh; overflow: auto;">

							<Table :tableData="tableData" :page="page" @handleModify="handleModify"
								@handleDel="handleDel" @handleSelectionChange="handleSelectionChange" from="dept">
							</Table>
						</div>
					</el-card>


				</el-col>
			</el-row>
			<el-dialog title="节点详情" :visible.sync="isShowModal" :close-on-click-modal="false" :destroy-on-close="true">
				<el-form :model="formData" ref="adminUserInfo" label-width="120px"
					style="max-height: 500px;overflow-y: auto;min-height: 300px;">
					<el-form-item label="上级节点">
						<el-cascader ref="formEditCascder" :options="treeData" :disabled="modalTitle == '修改'"
							:props="defaultProps" v-model="formData.parent_id" :show-all-levels="false"
							@change="handleCascaderChange" placeholder="请选择" filterable style="width: 50%">
						</el-cascader>
					</el-form-item>
					<el-form-item label="节点类型" required>

						<el-select v-model="formData.type" placeholder="请选择类型" style="width:50%">
							<el-option label="请选择类型" value=""></el-option>
							<el-option label="学校" value="xx"></el-option>
							<el-option label="部门" value="bm"></el-option>
							<el-option label="院系" value="yx"></el-option>
							<el-option label="专业" value="zy"></el-option>
							<el-option label="年级" value="nj"></el-option>
							<el-option label="届次" value="jc"></el-option>
							<el-option label="班级" value="bj"></el-option>
							<el-option label="实习小组" value="sxxz"></el-option>

						</el-select>


					</el-form-item>

					<el-row v-if="formData.type == 'nj' || formData.type == 'jc'">
						<el-col :span="10">
							<el-form-item label="实习开始时间">
								<el-date-picker value-format="yyyy-MM-dd" v-model="formData.practice_start_date"
									style="width: 80%" type="date" placeholder="选择日期">
								</el-date-picker>
							</el-form-item>
						</el-col>
						<el-col :span="10">
							<el-form-item label="实习结束时间">
								<el-date-picker value-format="yyyy-MM-dd" v-model="formData.practice_end_date"
									style="width: 80%" type="date" placeholder="选择日期">
								</el-date-picker>

							</el-form-item>
						</el-col>
					</el-row>



					<el-form-item label="节点名称" required>
						<el-input v-model.trim="formData.name" style="width: 50%" />
					</el-form-item>
					<el-form-item label="节点代码" required>
						<el-input v-model.trim="formData.code" style="width: 50%" />
					</el-form-item>

					<el-form-item label="节点角色">
						<el-row>
							<el-col :span="18">
								<el-select v-model="formData.roles" @change="resetRoles" multiple placeholder="请选择"
									style="width:80%">
									<el-option v-for="(item, index) in deptRoleList" :key="index"
										:label="item.role_name" :value="item.id"></el-option>
								</el-select>
							</el-col>
							<el-col :span="6">



								<el-button type="text" size="small" @click="showRoleAuth">
									数据权限
								</el-button>
								<el-button type="text" size="small" @click="showDeptroleManage = true">
									角色管理
								</el-button>




							</el-col>
						</el-row>
						<div style="font-size:12px;color:orangered">设置数据权限可限制该节点角色查看的数据范围，不设置默认按层级</div>
					</el-form-item>




					<el-form-item label="是否毕业" required v-if="formData.type == 'jc'">

						<el-select v-model="formData.biye" placeholder="请选择是否毕业" style="width:50%">
							<el-option label="实习中" :value="0"></el-option>
							<el-option label="已毕业" :value="1"></el-option>


						</el-select>


					</el-form-item>
					<el-form-item label="排序号" prop="sort" required>
						<el-input-number :min="0" v-model.trim="formData.sort" placeholder="请输入排序号(数字)"
							controls-position="right" />
					</el-form-item>

				</el-form>
				<el-row>
					<el-col :span="22" style="text-align: right;">
						<el-button size="small" type="default" @click="isShowModal = false">取消</el-button>
						<el-button size="small" type="primary" @click="submitForm('adminUserInfo')">保存</el-button>
					</el-col>
				</el-row>
			</el-dialog>

			<TForm v-if="showTeacherInfo" :teacher_id="teacher_id" :dept_id="deptId"></TForm>


			<el-dialog title="数据权限" :visible.sync="isShowModalAuth" width="800px" :close-on-click-modal="false"
				:destroy-on-close="true">

				<div style="display:flex;">
					<div style="width:150px">
						<el-tabs tab-position="left" v-model="activeRoleID" @tab-click="handleRoleChange">
							<el-tab-pane :label="r.role_name" :name="'' + r.role_id" v-for="(r, i) in DataAuth"
								:key="i">
							</el-tab-pane>

						</el-tabs>
					</div>
					<div style="">
						<el-tree ref="dataauthtree" :data="treeData" node-key="id" :props="firstCascaderProps"
							:default-expand-all="true" :expand-on-click-node="false" :show-checkbox="true"
							@check-change="checkAuthData">
							<div class="custom-tree-node flexBetween" slot-scope="{ node, data }" style="width: 100%;">
								<div>
									<span>{{ node.data.name }}</span>
									<span class="nodetag" style="background-color:chocolate"
										v-if="node.data.type == 'xx'">校级</span>
									<span class="nodetag" style="background-color:crimson"
										v-if="node.data.type == 'yx'">院系</span>
									<span class="nodetag" style="background-color:cornflowerblue"
										v-if="node.data.type == 'zy'">专业</span>
									<span class="nodetag" style="background-color:yellowgreen"
										v-if="node.data.type == 'bm'">部门</span>
									<span class="nodetag" style="background-color:aqua"
										v-if="node.data.type == 'nj'">年级</span>
									<span class="nodetag" style="background-color:blue"
										v-if="node.data.type == 'jc'">届次</span>
									<span class="nodetag" style="background-color:plum"
										v-if="node.data.type == 'bj'">班</span>
									<span class="nodetag" style="background-color:purple"
										v-if="node.data.type == 'sxxz'">组</span>
								</div>

							</div>
						</el-tree>
					</div>
				</div>

				<el-row>
					<el-col :span="22" style="text-align: right;">
						<el-button size="small" type="default" @click="isShowModalAuth = false">取消</el-button>
						<el-button size="small" type="primary" @click="setOk()">设置完成</el-button>
					</el-col>
				</el-row>
			</el-dialog>



		</div>

		<deptrole v-if="showDeptroleManage"></deptrole>

		<div style="display:none">


			<el-upload style="display: inline-block;" action="" :multiple="true" :http-request="uploadTemp"
				:on-success="handleXlsUpload2" :show-file-list="false" name="image">
				<el-button id="btnimport2" style="margin-left: 10px;" icon="el-icon-upload">
					选择文件</el-button>

			</el-upload>


		</div>

		<el-dialog title="导入教师" :visible.sync="importTeacherShow" width="800px" :close-on-click-modal="false"
			:destroy-on-close="true">
			<div style="text-align:center">
				<el-upload style="display: inline-block;" action="" :multiple="true" :http-request="uploadTemp"
					:on-success="handleXlsUpload" :show-file-list="false" name="image">
					<el-button id="btnimport1" style="margin-left: 10px; " icon="el-icon-upload">
						选择文件</el-button>



				</el-upload>
				<el-button style="margin-left: 10px;" type="text" icon="el-icon-download">
					<a href="/xls/教师数据导入模板.xlsx" style="text-decoration: none;color:inherit">下载教师模板</a>
				</el-button>
			</div>

		</el-dialog>


	</div>
</template>
<script>
import api from '@/api/api';
import User from './components/User'
import Table from './components/TeacherTable'
import TForm from './components/TeacherForm'
import deptuser from './components/deptuser'
import deptrole from './sys_deptRole'
export default {
	name: 'dept',
	components: {
		User,
		Table,
		deptuser,
		TForm,
		deptrole
	},
	data() {
		return {
			visible: false,
			isShowModal: false,
			isShowModalAuth: false,
			importTeacherShow: false,
			modalTitle: '添加',
			DeptUserDialog: false,
			showDeptroleManage: false,
			activeRoleID: "",
			formData: {},
			fgxz: [],
			activeName: 'CN',
			tableData: [],

			teacherList: [],
			editUser: {},
			deptId: null,
			editDeptRole: {},
			deptRoleList: [],
			isShowDeptRole: false,
			biye: 0,

			page: {

			},
			searchForm: {

				ktype: "username",
				keyword: ""
			},
			multipleSelection: [],
			treeData: [],
			ckdTreeData: [],
			firstCascaderProps: {
				label: "name",
				children: "children",
			},
			defaultProps: {
				label: 'name',
				value: 'id',
				children: 'children',
				emitPath: false,
				checkStrictly: true
			},
			DataAuth: [],
			showTeacherInfo: false,
			teacher_id: 0
		}
	},

	mounted() {
		// this.getTree();
		// this.getDeptRoles()
		this.getTeachers()
	},
	methods: {
		getTree() {

			this.$http.post("/api/sys_office_tree", { parent_id: 0, biye: this.biye }).then(res => {
				this.treeData = res.data.tree
				this.ckdTreeData = this.treeData
			})
		},
		handleCommand(e) {

			if (e == "addTeacher") {
				this.addTeacher()
			} else if (e == "addStudents") {
				this.addStudents()
			} else if (e == "importTeacher") {
				$("#btnimport1").click()
			} else if (e == "importOrg") {
				$("#btnimport2").click()
			} else if (e == "downTechTemp") {
				window.open("/xls/教师数据导入模板.xlsx", "_blank")
			} else if (e == "downOrgTemp") {
				window.open("/xls/组织机构导入模板.xls", "_blank")
			}
		},
		handleXlsUpload(e) {//教师导入

			this.$http.post("/api/import_teacher_xls", { url: e.src }).then((res) => {
				this.$message.success('导入成功')
				this.importTeacherShow = false
				this.getTeachers();
			});
		},
		handleXlsUpload2(e) {//组织架构导入
			this.$http.post("/api/import_office_xls_new", { url: e.src }).then((res) => {
				this.$message.success('导入成功')
				this.getTree();
			});
		},
		setBiyeType() {
			if (this.biye === 0) {
				this.biye = 1
			} else if (this.biye === 1) {
				this.biye = ""
			} else if (this.biye === "") {
				this.biye = 0
			}
			this.getTree()
		},

		getTeachers() {
			let params = {
				page: this.page.current_page,
				pagesize: this.page.per_page?this.page.per_page:20,
				ktype: this.searchForm.ktype,
				keyword: this.searchForm.keyword,
				dept_id: this.searchForm.keyword ? 0 : this.deptId,
				biye: this.biye
			};


			// sys_dept_user_related_list
			this.$http.post("/api/t_sch_teachers_list", params).then((res) => {
				this.tableData = res.data.data;
				this.page = res.data.page;
			});
		},
		addTeacher() {
			this.teacher_id = 0;
			this.showTeacherInfo = true;

		},
		getTechByIds(ids) {
			if (ids) {
				this.$http.post("/api/get_teacher_byids", {
					ids: ids
				}).then((res) => {
					this.fgxz = res.data
				});
			} else {
				this.fgxz = []
			}
		},
		getUser(e) {
			if (e && e.length > 0) {
				this.fgxz = e
				this.DeptUserDialog = false
				this.formData.fgxz = e[0].id
			}

		},
		getDeptRoles() {
			this.$http.post("/api/sys_dept_role_list").then(res => {
				this.deptRoleList = res.data.data
			})
		},

		handleNodeClick(data) {
			console.log(data);
			this.deptId = data.id;
			this.deptInfo = data;

			this.getTeachers()
			this.ckdTreeData = [data]


		},
		handleSelectionChange(val) {
			let _this = this;
			_this.multipleSelection = val;
		},
		formAdd() {
			this.formData = {
				parent_id: 0,
				sort: 1,
				type: "",
				name: "",
				roles: []
			}
			this.formData.type = ''
			this.modalTitle = '新增部门'
			this.isShowModal = true;
		},
		exportXls() {
			this.$http.post('/api/teacher_dept_xls').then(res => {
				window.open(res.data.url, "_blank")
			})
		},
		handleAdd(row) {
			this.formData = {
				parent_id: row.id,
				sort: 1,
				type: "",
				name: "",
				pids: "",
				roles: []
			}
			this.modalTitle = '新增部门'
			this.isShowModal = true;
		},
		handleModify(row) {
			this.isShowModal = true;
			let formData = JSON.parse(JSON.stringify(row))
			if (formData.roles && formData.roles.length > 0) {
				formData.roles = formData.roles.map(e => { return e.role_id })
				let DataAuth = JSON.parse(JSON.stringify(row.roles))
				for (let item of DataAuth) {
					if (item.dept_data) {
						item.dept_data = item.dept_data.split(",")
					} else {
						item.dept_data = []
					}

				}
				if (DataAuth.length > 0) {
					this.activeRoleID = DataAuth[0].role_id + ""
				}
				this.DataAuth = DataAuth
			} else {
				this.DataAuth = []
			}

			this.formData = formData

			//this.getTechByIds(row.fgxz)
		},
		resetRoles() {

			let extItem = [];
			//删除移除的角色
			for (let er of this.DataAuth) {
				let ext = false;
				for (let r of this.formData.roles) {
					if (er.role_id == r) {
						ext = true;
						break
					}
				}
				if (ext) {
					extItem.push(er)
				}
			}
			this.DataAuth = extItem
			//添加新增的角色..
			for (let r of this.formData.roles) {
				let ext = false;
				for (let er of this.DataAuth) {
					if (r == er.role_id) {
						ext = true
						break
					}
				}
				if (!ext) {
					let role_name = "";
					for (let item of this.deptRoleList) {
						if (item.id == r) {
							role_name = item.role_name
						}
					}
					this.DataAuth.push({ role_id: r, role_name: role_name, dept_data: [] })
				}

			}


		},
		handleDel(row) {

			this.$http.post("/api/t_sch_teachers_delete", {
				id: String(row.id)
			}).then(res => {
				this.$message.success('删除成功')
				this.getTeachers();
			})
		},
		handleCascaderChange(e) {
			console.log(1, e)
		},

		submitForm(formName) {
			let pids = "0";
			try {
				pids = this.$refs["formEditCascder"].getCheckedNodes(true)[0].data.pids
				if (pids) {
					pids = pids.replace(",0", "")
				} else {
					pids = "0"
				}
			} catch (error) {

			}



			let params = {
				...this.formData
			}
			if (!params.parent_id) {
				params.parent_id = 0
			}
			let roleauth = [];
			if (params.roles && params.roles.length > 0) {
				for (let r of params.roles) {
					for (let er of this.DataAuth) {
						if (r == er.role_id) {
							roleauth.push({ role_id: r, dept_data: er.dept_data.join(",") })
						}
					}
				}
				params.roles = roleauth
			} else {
				params.roles = []
			}
			params.pids = pids + "," + params.parent_id + ",0"
			this.$http.post("/api/sys_office_edit", params).then(res => {
				this.$message.success('保存成功')
				this.getTree();
				this.isShowModal = false;
			})
		},
		showUser() {
			this.editUser = {}
			this.$refs.visiable_user.show()

		},
		saveUser(e, oldSlctList) {


			this.$http.post('/api/sys_dept_user_related_edit', {
				json: paramsArr
			}).then(res => {
				this.$message.success('保存成功')
				this.getTree();
				this.$refs.visiable_user.handleCancel()
			})
		},

		getUnique2(arr) {
			const map = {};
			// 1、把数组元素作为对象的键存起来（这样就算有重复的元素，也会相互替换掉）
			arr.forEach(item => map[JSON.stringify(item)] = item);

			// 2、再把对象的值抽成一个数组返回即为不重复的集合
			return Object.keys(map).map(key => map[key])
		},


		showDeptRole(row) {
			this.deptId = row.id
			this.isShowDeptRole = true
		},
		saveDeptRole() {
			let ids = this.$refs["deptRoleTree"].getCheckedNodes(false, true).map(e => {
				return e.id
			}).join(",")
			console.log(ids)
			this.$http.post(api.deptRoleRelated, {
				dept_id: this.deptId,
				role_id: ids
			}).then(res => {
				this.$message.success('保存成功')
				this.getTree();
				this.isShowDeptRole = false
			})
		},
		showRoleAuth() {
			if (this.DataAuth.length == 0) {
				this.$message.error('请选择职位')
			} else {
				this.isShowModalAuth = true
				setTimeout(() => {
					if (this.DataAuth.length > 0) {
						this.$refs['dataauthtree'].setCheckedKeys(this.DataAuth[0].dept_data)
					}
				}, 300);
			}
		},
		setOk() {
			this.isShowModalAuth = false

		},
		handleRoleChange() {
			for (let tab of this.DataAuth) {
				if (tab.role_id == this.activeRoleID) {
					this.$refs['dataauthtree'].setCheckedKeys(tab.dept_data)
					break
				}
			}
		},
		checkAuthData(a, b, c) {
			for (let tab of this.DataAuth) {
				if (tab.role_id == this.activeRoleID) {
					tab.dept_data = this.$refs['dataauthtree'].getCheckedKeys()
					break
				}
			}


		}
	}
}
</script>
<style scoped lang="less" type="text/less">
.app-container {

	.treeContainer {
		height: 100%;
		background-color: #fff;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
		border: 1px solid rgba(220, 223, 230, 0.6);
		margin-right: 20px;
		padding-right: 10px;

		.treeTitle {
			height: 40px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			color: #409eff;
			padding-left: 6px;
		}
	}
}

.nodetag {
	display: inline-block;
	padding: 0 5px;
	font-size: 12px;
	transform: scale(0.8, 0.8);
	background-color: #409eff;
	color: #fff;
	border-radius: 4px;
}
</style>
